import styled from "styled-components";

export const OrderCard = styled.div`
  width: 100%;
  max-width: 400px;
  background-color: white;
  padding: 20px;
  margin: 0px 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

export const OrderHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const OrderPin = styled.span`
  font-size: 1.25rem;
  font-weight: bold;
`;

export const OrderState = styled.span`
  background-color: #6200ee;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
`;

export const TicketCard = styled.div`
  width: 100%;
  background-color: #f5f5f5;
  padding: 10px;
  margin: 10px 0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

export const TicketUserName = styled.h3`
  font-size: 1.25rem;
  margin-bottom: 10px;
  text-align: left;
`;

export const TicketNumberList = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const TicketNumberBall = styled.div<{ isDrawn: boolean }>`
  width: 35px;
  height: 35px;
  margin: 5px;
  border-radius: 50%;
  background-color: ${(props) => (props.isDrawn ? "#4caf50" : "#eee")};
  color: ${(props) => (props.isDrawn ? "#fff" : "#000")};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const ValueDetails = styled.div`
  display: flex;
  justify-content: right;
  font-size: 16px;
  font-weight: bold;
  color: #666;
`;
export const CancelOrder = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #6200ee; /* Ou qualquer cor que desejar para o link */
  cursor: pointer;
  text-decoration: underline; /* Para parecer um link */
  &:hover {
    color: #3700b3; /* Cor ao passar o mouse */
  }
`;

export const OrdersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 10px 0; /* Ajuste a margem conforme necessário */
`;

export const ContactManagerContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

export const ContactManagerLink = styled.a`
  display: flex;
  align-items: center;
  color: #25d366; // WhatsApp green color
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  & > svg {
    margin-right: 5px;
  }
`;
