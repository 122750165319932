import styled from "styled-components";
import { FaExclamationTriangle } from "react-icons/fa";
import Select from "react-select";

export const UserList = styled.div`
  padding-bottom: 60px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center; /* Alinha os UserCards ao centro */
`;

export const UserCard = styled.div`
  width: 100%;
  max-width: 400px;
  background-color: white;
  padding: 10px;
  margin: 10px 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

export const UserName = styled.div`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;

export const RoleBadge = styled.div<{ role: string }>`
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: ${(props) =>
    props.role === "ROLE_MANAGER" ? "#ffcccc" : "#ccffcc"};
  color: #333;
  padding: 5px 10px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-weight: bold;
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

export const Modal = styled.div`
  position: fixed;
  width: 70%;
  max-width: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; // Adicionando width 100% para responsividade
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  max-width: 300px; // Adicionando max-width para garantir que o select não exceda o tamanho desejado
  min-width: 200px; // Adicionando min-width para garantir um tamanho mínimo
`;

export const ModalButton = styled.button<{ isDanger?: boolean }>`
  background-color: #f5f5f5;
  color: #333;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: ${({ isDanger }) => (isDanger ? "space-between" : "center")};
  align-items: center;
  text-align: left;

  &:hover {
    background-color: ${({ isDanger }) => (isDanger ? "#ffcccc" : "#3700b3")};
    color: ${({ isDanger }) => (isDanger ? "#800000" : "white")};
  }

  & > svg {
    margin-left: 10px;
  }
`;

export const ExclamationIcon = styled(FaExclamationTriangle)`
  color: #b30000;
`;

export const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%; // Use toda a largura disponível do Container
  max-width: 100%; // Garanta que não ultrapasse a largura do Container
  margin-bottom: 30px;
`;

export const SearchInput = styled.input`
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 20px; /* Cantos mais arredondados */
  font-size: 16px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="%23ddd" width="12" height="12" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zm-5.442 1.062a5 5 0 1 1 0-10 5 5 0 0 1 0 10z"/></svg>');
  background-repeat: no-repeat;
  background-position: 10px center;
  padding-left: 40px;
  ::placeholder {
    color: #ddd; /* Cor cinza para o placeholder */
  }
`;