import React, { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useParams, useNavigate } from "react-router-dom";
import {
  getOrdersByDrawIdAndUserId,
  getDrawById,
  cancelOrder,
} from "../services/api";
import { OrderDTO, DrawDTO } from "../types";
import {
  OrderCard,
  OrderHeader,
  OrderPin,
  OrderState,
  TicketCard,
  TicketUserName,
  TicketNumberList,
  TicketNumberBall,
  ValueDetails,
  CancelOrder,
  OrdersContainer,
  ContactManagerContainer,
  ContactManagerLink,
} from "../styles/OrdersStyles";
import { useTranslation } from "react-i18next";
import NavBar from "../components/NavBar";
import {
  Container,
  BackButton,
  PageTitle,
  FloatingButton,
} from "../styles/GlobalStyles";
import { FaWhatsapp, FaPlus } from "react-icons/fa";
import { useGroup } from "../contexts/GroupContext";
import LoadingOverlay from "../components/LoadingOverlay";
import Pix from "../components/Pix";

const OrdersPage: React.FC = () => {
  const { user } = useAuth();
  const { drawId } = useParams<{ drawId: string }>();
  const { t } = useTranslation();
  const [orders, setOrders] = useState<OrderDTO[]>([]);
  const navigate = useNavigate();
  const [draw, setDraw] = useState<DrawDTO | null>(null);
  const { group } = useGroup();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchOrders = async () => {
      if (user?.id) {
        try {
          setIsLoading(true);
          const response = await getOrdersByDrawIdAndUserId(
            Number(drawId),
            user.id
          );
          setOrders(response);
        } catch (error) {
          console.error("Error fetching orders:", error);
        } finally {
          setIsLoading(false);
        }
      }

      if (drawId) {
        const drawResponse = await getDrawById(Number(drawId));
        setDraw(drawResponse);
      }
    };

    fetchOrders();
  }, [user]);

  const handleCreateOrderClick = () => {
    navigate(`/draw/${drawId}/${user?.id}/create`);
  };

  const handleCancelOrder = async (order: OrderDTO) => {
    try {
      const cancelOrderResponse = await cancelOrder(order.pin);
      setOrders((prevOrders) =>
        prevOrders.map((o) =>
          o.pin === order.pin ? { ...o, ...cancelOrderResponse } : o
        )
      );
    } catch (error) {
      console.error("Error cancelling order:", error);
    }
  };

  return (
    <Container>
      {isLoading && <LoadingOverlay />}
      <BackButton onClick={() => navigate("/draw/" + drawId)}>{"<"}</BackButton>
      <PageTitle>{t("order.title")}</PageTitle>
      {orders
        .sort((a, b) => {
          return b.id - a.id;
        })
        .map((order) => (
          <OrderCard key={order.id}>
            <OrderHeader>
              <OrderPin>
                {t("order.pin")}: {order.pin}
              </OrderPin>
              <OrderState>
                {t(`orderState.${order.state.toLowerCase()}`)}
              </OrderState>
            </OrderHeader>
            <div>
              <TicketUserName>
                {t("order.promoter")}: {order.promoter.name}
              </TicketUserName>
              <Pix
                pixCode={order.promoterPixCode}
                pixType={order.promoterPixType}
              ></Pix>
              {order.promoterPhone && (
                <ContactManagerContainer>
                  <ContactManagerLink
                    href={
                      `https://wa.me/${order.promoterPhone}?text=${t(
                        "order.whatsappMsg"
                      )}${order.pin}` +
                      t("order.whatsappMsg2") +
                      draw?.name +
                      t("order.whatsappMsg3") +
                      group?.name
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaWhatsapp size={20} />
                    {t("order.contact")}
                  </ContactManagerLink>
                </ContactManagerContainer>
              )}
              {order.tickets.map((ticket) => (
                <TicketCard key={ticket.id}>
                  <TicketNumberList>
                    {ticket.numbers.map((number) => (
                      <TicketNumberBall
                        key={number.id}
                        isDrawn={number.isDrawn}
                      >
                        {number.number}
                      </TicketNumberBall>
                    ))}
                  </TicketNumberList>
                </TicketCard>
              ))}
            </div>
            <br />
            {order.state === "PENDING" && (
              <OrdersContainer>
                <CancelOrder onClick={() => handleCancelOrder(order)}>
                  <span>{`${t("order.cancelOrder")}`}</span>
                </CancelOrder>
                <ValueDetails>
                  <span>{`${t("order.total")}: $${order.total}`}</span>
                </ValueDetails>
              </OrdersContainer>
            )}
            {order.state !== "PENDING" && (
              <ValueDetails>
                <span>{`${t("order.total")}: $${order.total}`}</span>
              </ValueDetails>
            )}
          </OrderCard>
        ))}
      {draw?.state === "INITIATED" && (
        <FloatingButton onClick={handleCreateOrderClick}>
          <FaPlus />
        </FloatingButton>
      )}
      <NavBar />
    </Container>
  );
};

export default OrdersPage;
