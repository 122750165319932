import axiosInstance from "./axiosConfig";
import {
  UserDTO,
  GroupDTO,
  DrawDTO,
  OrderDTO,
  GroupSummaryDTO,
  NotificationMessageDTO,
  TicketDTO,
  UserGroupDTO,
  FinancialMovementDTO,
  PixType,
} from "../types";

/* export const API_BASE_URL = "http://localhost:8080"; */
export const API_BASE_URL = "https://www.sorterapida.com.br";
export const API_URL = API_BASE_URL + "/api";

export const register = async (userData: Partial<UserDTO>) => {
  try {
    const response = await axiosInstance.post("/users/register", userData);
    return response.data;
  } catch (error) {
    throw new Error("Error registering user");
  }
};

export const updateUser = async (userData: Partial<UserDTO>) => {
  try {
    const response = await axiosInstance.post("/users/update", userData);
    return response.data;
  } catch (error) {
    throw new Error("Error updating user");
  }
};

export const login = async (username: string, password: string) => {
  const response = await axiosInstance.post("/authenticate", {
    username,
    password,
  });
  return {
    jwt: response.data.jwt,
    userDetails: response.data.user,
  };
};

export const setAuthToken = (token: string | null) => {
  if (token) {
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axiosInstance.defaults.headers.common["Authorization"];
  }
};

export const getGroupsByUserId = async (userId: number) => {
  const response = await axiosInstance.get("/groups/findByUserId", {
    params: { userId },
  });
  return response.data;
};

export const getAllGroups = async () => {
  const response = await axiosInstance.get("/groups/findAll");
  return response.data;
};

export const getUserDetails = async (): Promise<UserDTO> => {
  const response = await axiosInstance.get("/users/me");
  return response.data;
};

export const getGroupByPin = async (pin: number) => {
  const response = await axiosInstance.get("/groups/findByPin", {
    params: { pin },
  });
  return response.data;
};

export const getGroupById = async (id: number) => {
  const response = await axiosInstance.get("/groups/findById", {
    params: { id },
  });
  return response.data;
};

export const getDrawById = async (drawId: number): Promise<DrawDTO> => {
  const response = await axiosInstance.get(`${API_URL}/draws/${drawId}`);
  return response.data;
};

export const getLotteryResultsByDrawId = async (drawId: number) => {
  const response = await axiosInstance.get(
    `${API_URL}/lotteryResults/listByDraw`,
    {
      params: { drawId },
    }
  );
  return response.data;
};

export const getUserById = async (userId: number): Promise<UserDTO> => {
  const response = await axiosInstance.get(`/users/${userId}`);
  return response.data;
};

export const existUsername = async (username: string): Promise<Boolean> => {
  const response = await axiosInstance.get(
    `/users/validate/username?username=${username}`
  );
  return response.data;
};

export const existEmail = async (email: string): Promise<Boolean> => {
  const response = await axiosInstance.get(
    `/users/validate/email?email=${email}`
  );
  return response.data;
};

export const getUsersByGroupId = async (groupId: number) => {
  const response = await axiosInstance.get(`/groups/${groupId}/list`);
  return response.data;
};

export const promoteToPromoter = async (groupId: number, userId: number) => {
  const response = await axiosInstance.post(
    `${API_URL}/groups/${groupId}/promote/${userId}`
  );
  return response.data;
};

export const demotePromoter = async (groupId: number, userId: number) => {
  const response = await axiosInstance.post(
    `${API_URL}/groups/${groupId}/demote/${userId}`
  );
  return response.data;
};

export const defineUserPromoter = async (
  groupId: number,
  userId: number,
  promoterId: number
) => {
  const response = await axiosInstance.post(
    `${API_URL}/groups/${groupId}/user/${userId}/promoter/${promoterId}`
  );
  return response.data;
};

export const removeFromGroup = async (groupId: number, user: UserDTO) => {
  const response = await axiosInstance.post(
    `${API_URL}/groups/${groupId}/remove`,
    user
  );
  return response.data;
};

export const joinGroup = async (pin: number, user: UserDTO) => {
  const response = await axiosInstance.post(
    `${API_URL}/groups/pin/${pin}/join`,
    user
  );
  return response.data;
};

export const uploadImage = async (file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  const response = await axiosInstance.post(`/images/upload`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response;
};

export const createGroup = async (
  groupData: Partial<GroupDTO>,
  creatorId: number
) => {
  const response = await axiosInstance.post(
    `${API_URL}/groups/create?creatorId=${creatorId}`,
    groupData
  );
  return response.data;
};

export const createDraw = async (drawData: Partial<DrawDTO>) => {
  const response = await axiosInstance.post("/draws/create", drawData);
  return response.data;
};

export const changeDrawName = async (drawData: Partial<DrawDTO>) => {
  const response = await axiosInstance.post("/draws/change/name", drawData);
  return response.data;
};

export const getOrdersByDrawIdAndUserId = async (
  drawId: number,
  userId: number
) => {
  const response = await axiosInstance.get<OrderDTO[]>(
    `${API_URL}/orders/${drawId}/${userId}/list`
  );
  return response.data;
};

export const createOrder = async (orderData: Partial<OrderDTO>) => {
  const response = await axiosInstance.post(
    `${API_URL}/orders/create`,
    orderData
  );
  return response.data;
};

export const getPromotersByGroupId = async (groupId: number) => {
  const response = await axiosInstance.get<UserGroupDTO[]>(
    `${API_URL}/users/group/${groupId}/promoters`
  );
  return response.data;
};

export const getOrdersByDrawIdAndPromoterId = async (
  drawId: number,
  promoterId: number
) => {
  const response = await axiosInstance.get<OrderDTO[]>(
    `${API_URL}/orders/${drawId}/${promoterId}/list/promoter`
  );
  return response.data;
};

export const validateOrder = async (pin: string) => {
  const response = await axiosInstance.post(
    `${API_URL}/orders/${pin}/validate`,
    pin
  );
  return response.data;
};

export const startDraw = async (drawId: number) => {
  const response = await axiosInstance.post(
    `${API_URL}/draws/${drawId}/startInProgress`,
    drawId
  );
  return response.data;
};

export const cancelOrder = async (pin: string) => {
  const response = await axiosInstance.post(
    `${API_URL}/orders/${pin}/cancel`,
    pin
  );
  return response.data;
};

export const generateInviteLink = async (
  groupId: number,
  promoterId: number
): Promise<string> => {
  const response = await axiosInstance.post<string>(
    `${API_URL}/invites/generate`,
    null,
    {
      params: { groupId, promoterId },
    }
  );
  return response.data;
};

export const acceptInvite = async (
  token: string,
  userId: number
): Promise<boolean> => {
  try {
    const response = await axiosInstance.get(`${API_URL}/invites/accept`, {
      params: { token, userId },
    });
    console.log("acceptInvite: API call finished", response.data);
    return response.data;
  } catch (error) {
    console.error("Error in acceptInvite:", error);
    return false;
  }
};

export const activateGroup = async (groupId: number) => {
  const response = await axiosInstance.post(
    `${API_URL}/groups/${groupId}/activate`,
    groupId
  );
  return response.data;
};

export const deleteGroup = async (groupId: number) => {
  const response = await axiosInstance.post(
    `${API_URL}/groups/${groupId}/delete`,
    groupId
  );
  return response.data;
};

export const closeGroup = async (groupId: number) => {
  const response = await axiosInstance.post(
    `${API_URL}/groups/${groupId}/close`,
    groupId
  );
  return response.data;
};

export const getGroupSummary = async (groupId: number) => {
  const response = await axiosInstance.get<GroupSummaryDTO>(
    `/groups/summary?groupId=${groupId}`
  );
  return response.data;
};

export const existGroupName = async (name: string): Promise<Boolean> => {
  const response = await axiosInstance.get(
    `/groups/validate/name?name=${name}`
  );
  return response.data;
};

export const getNotificationsForUser = async (
  userId: number
): Promise<NotificationMessageDTO[]> => {
  const response = await axiosInstance.get(`/notifications/user/${userId}`);
  return response.data;
};

export const clearNotificationForUser = async (
  userId: number
): Promise<Boolean> => {
  const response = await axiosInstance.post(`/notifications/user/${userId}`);
  return response.data;
};

export const updateGroup = async (
  groupData: Partial<GroupDTO>
): Promise<GroupDTO> => {
  const response = await axiosInstance.post(
    `${API_URL}/groups/update`,
    groupData
  );
  return response.data;
};

export const getLatestTickets = async (drawId: number, userId: number) => {
  const response = await axiosInstance.get<TicketDTO[]>(
    `/draws/latest/tickets/${drawId}/${userId}`
  );
  return response.data;
};

export const defineUserPromoterLimit = async (
  groupId: number,
  userId: number,
  limit: number
): Promise<UserGroupDTO> => {
  const response = await axiosInstance.post<UserGroupDTO>(
    `${API_URL}/groups/${groupId}/user/${userId}/promoter/limit`,
    null,
    {
      params: { limit },
    }
  );
  return response.data;
};

export const defineUserPromoterPix = async (
  groupId: number,
  userId: number,
  pix: string,
  pixType: PixType
): Promise<UserGroupDTO> => {
  const response = await axiosInstance.post<UserGroupDTO>(
    `${API_URL}/groups/${groupId}/user/${userId}/promoter/pix`,
    null,
    { params: { pix, pixType } }
  );
  return response.data;
};

export const addMovement = async (
  groupId: number,
  promoterId: number,
  amount: number
): Promise<FinancialMovementDTO> => {
  const response = await axiosInstance.post<FinancialMovementDTO>(
    `${API_URL}/financial/movement/add/received/${groupId}/${promoterId}`,
    null,
    {
      params: { amount },
    }
  );
  return response.data;
};

export const listByGroupAndPromoter = async (
  groupId: number,
  promoterId: number
): Promise<FinancialMovementDTO[]> => {
  const response = await axiosInstance.get<FinancialMovementDTO[]>(
    `${API_URL}/financial/movement/list/${groupId}/${promoterId}`
  );
  return response.data;
};

export const isOverLimit = async (
  groupId: number,
  userId: number,
  orderValue: number
): Promise<boolean> => {
  const response = await axiosInstance.get<boolean>(
    `${API_URL}/groups/${groupId}/user/${userId}/promoter/overlimit`,
    {
      params: { orderValue },
    }
  );
  return response.data;
};

export const sendEmail = async (to: string, subject: string, text: string) => {
  const response = await axiosInstance.get("/email/send-email", {
    params: { to, subject, text },
  });
  return response.data;
};

// Função para redefinir a senha usando o token
export const resetPassword = async (
  token: string | null,
  newPassword: string
) => {
  const response = await axiosInstance.post(
    `${API_URL}/users/reset-password`,
    null,
    {
      params: {
        token,
        newPassword,
      },
    }
  );
  return response.data;
};

export const requestPasswordReset = async (email: string) => {
  try {
    await axiosInstance.post(`${API_URL}/users/generate-reset-token`, null, {
      params: { email },
    });
    // Retorna sucesso sem a necessidade de enviar o e-mail manualmente
    return { success: true };
  } catch (error) {
    throw new Error("Error requesting password reset");
  }
};
